//
// Card stats
//

.card-stats {
    background-color: $gray-200;
    .card-body {
        padding: 1rem 1.5rem;
    }

    .card-status-bullet {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(50%, -50%);
    }
    .territory-name {
        display: flex;
        p {
            margin-bottom: 0;
            font-weight: $font-weight-bold;
        }
        .territory-name-short {
            font-size: 46px;
            line-height: 54px;
            white-space: nowrap;
        }
        .territory-name-full {
            margin-top: 8px;
            margin-left: 6px;
            line-height: 20px;
            word-spacing:9999px;
        }
    }
    .report-territory {
        display: block;
        .territory-name-short {
            font-size: 32px;
            line-height: 38px;
        }
        .territory-name-full {
            margin-top: 4px;
            margin-left: 0;
            line-height: 20px;
            word-spacing:unset;
        }
    }
    .stats-percentage {
        font-size: 22px;
        line-height: 42px;
    }
    .agent-amount {
        font-size: 24px;
        font-weight: $font-weight-bold;
        color: $yiq-text-dark
    }

}
.overview-stats {
    .stats-percentage {
        font-size: 66px;
        line-height: 66px;
    }
}
.reports-card {
    .floating-btn {
        width: 100%;
        position: absolute;
        text-align: center;
        bottom: 6px;
        transform: scale(0);
        transition: 0.2s ease-in;
    }

    &:hover {
        .floating-btn {
            transform: scale(1);
        }
    }
}
.shops-card {
  .store-bg {
    position: absolute;
    font-size: 6rem;
    z-index: 0;
    top: 18px;
    right: 8px;
    opacity: 0.05;
  }
  .card-body {
    position: relative;
    z-index: 1;
  }
}
