//
// Type
//


// Paragraphs

p {
	font-size: $paragraph-font-size;
	font-weight: $paragraph-font-weight;
	line-height: $paragraph-line-height;
}

.lead {
	font-size: $lead-font-size;
  	font-weight: $lead-font-weight;
	line-height: $paragraph-line-height;
	margin-top: 1.5rem;

	+ .btn-wrapper {
		margin-top: 3rem;
	}
}

.description {
	font-size: $font-size-sm;
}


//Reward Colors
.span-gold, .span-Gold {
	color: #FFDF00;
	&:before {
		content: 'Gold';
	}
}
.span-silver, .span-Silver {
	color: #C0C0C0;
	&:before {
		content: 'Silver';
	}
}
.span-bronze, .span-Bronze {
	color: #cd7f32;
	&:before {
		content: 'Bronze';
	}
}
.span-diamond, .span-Diamond {
	color: #B9F2FF;
	&:before {
		content: 'Diamond';
	}
}
.span-platinum, .span-Platinum {
	color: #B9F2FF;
	&:before {
		content: 'Platinum';
	}
}
.span-warning, .span-Warning {
	color: $danger;
	&:before {
		content: 'Warning';
	}
}
