//
// Heading
//


// General styles

.heading {
	letter-spacing: $heading-letter-spacing;
	font-size: $heading-font-size;
	text-transform: none;
	font-weight: $heading-font-weight;
}


// Heading variations

.heading-small {
	padding-top: .25rem;
	padding-bottom: .25rem;
	font-size: .75rem;
	text-transform: uppercase;
	letter-spacing: .04em;
}

.heading-title {
	letter-spacing: $heading-title-letter-spacing;
	font-size: $heading-title-font-size;
	font-weight: $heading-title-font-weight;
	text-transform: $heading-title-text-transform;
}

.heading-section {
	letter-spacing: $heading-section-letter-spacing;
	font-size: $heading-section-font-size;
	font-weight: $heading-section-font-weight;
	text-transform: $heading-section-text-transform;

	img {
		display: block;
		width: 72px;
		height: 72px;
		margin-bottom: 1.5rem;
	}

	&.text-center {
		img {
			margin-left: auto;
			margin-right: auto;
		}
	}
}
