//
// Input group
//

.input-group {
	box-shadow: $input-box-shadow;
	border-radius: $input-border-radius;
	transition: $transition-base;

	.form-control {
		box-shadow: none;

		&:not(:first-child) {
		    border-left: 0;
		    padding-left: 0;
		}
		&:not(:last-child) {
			border-right: 0;
			padding-right: 0;
		}
		&:focus {
			box-shadow: none;
		}
	}
}

.input-group-text {
    transition: $input-transition;
}


// Alternative input groups related to .form-control-alternative


.input-group-alternative {
    box-shadow: $input-alternative-box-shadow;
    border: 0;
    transition: box-shadow .15s ease;

    .form-control,
    .input-group-text {
        border: 0;
        box-shadow: none;
    }
}

.focused {
	.input-group-alternative {
        box-shadow: $input-focus-alternative-box-shadow !important;
    }
}


// .focus class is applied dinamycally from theme.js

.focused {
	.input-group {
		box-shadow: $input-focus-box-shadow;
	}

	.input-group-text {
	    color: $input-group-addon-focus-color;
	    background-color: $input-group-addon-focus-bg;
	    border-color: $input-group-addon-focus-border-color;
	}

	.form-control {
		border-color: $input-group-addon-focus-border-color;
	}
}
// React Datepicker
.react-datepicker-wrapper, .react-datepicker__input-container {
	width: 186px;
	display: block !important;

	input {
		display: block;
		width: 100%;
		height: calc(2.75rem + 2px);
		padding: .625rem .75rem;
		font-size: 1rem;
		line-height: 1.5;
		color: #8898aa;
		background-color: #fff;
		background-clip: padding-box;
		border-radius: .375rem;
		box-shadow: 0 1px 3px rgba(50,50,93,.15), 0 1px 0 rgba(0,0,0,.02);
		border: 0;
		transition: box-shadow .15s ease;
		&:hover {
			cursor: pointer;
		}
	}
}
