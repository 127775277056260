//
// Avatar
//

// General styles

.avatar {
	color: $white;
	background-color: $gray-500;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-size: 1rem;
	border-radius: 50%;
	height: 48px;
	width: 48px;

    img {
    	width: 100%;
    	border-radius: 50%;
		object-fit: cover;
		object-position: center;
    }

    + .avatar-content {
    	display: inline-block;
    	margin-left: .75rem;
    }
}


// Avatar size variations

.avatar-xl {
	min-width: 120px;
	max-width: 120px;
	min-height: 120px;
	max-height: 120px;
	font-size: $font-size-sm;
}

.avatar-lg {
	min-width: 72px;
	max-width: 72px;
	min-height: 72px;
	max-height: 72px;
	font-size: $font-size-sm;
}

.avatar-sm {
	min-width: 36px;
	max-width: 36px;
	min-height: 36px;
	max-height: 36px;
	font-size: $font-size-sm;
}
