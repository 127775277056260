//
// Forms
//


// Labels

.form-control-label {
  color: $gray-700;
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;
}


// Text inputs

.form-control {
  font-size: $input-btn-font-size;

  &:focus {
    &::placeholder {
      color: $input-focus-placeholder-color;
    }
  }
}


// Textarea

textarea[resize="none"] {
  resize: none !important;
}

textarea[resize="both"] {
  resize: both !important;
}

textarea[resize="vertical"] {
  resize: vertical !important;
}

textarea[resize="horizontal"] {
  resize: horizontal !important;
}


// Form input variations

// Muted input

.form-control-muted {
  background-color: $input-muted-bg;
  border-color: $input-muted-bg;
  box-shadow: none;

  &:focus {
    background-color: $input-focus-muted-bg;
  }
}


// Alternative input

.form-control-alternative {
  box-shadow: $input-alternative-box-shadow;
  border: 0;
  transition: box-shadow .15s ease;

  &:focus {
    box-shadow: $input-focus-alternative-box-shadow;
  }
}

// Size variations: Fixes to the bootstrap defaults

.form-control-lg {
  font-size: $font-size-base;
}

.date-filter {

}

@media only screen and (max-width: 600px) {
  .date-filter {

    p {
      margin-bottom: 4px !important;
    }

    h2 {
      font-size: 14px !important;
      display: none;
    }

    .form-group {
      margin-top: 12px !important;
      margin-bottom: 12px !important;

      .form-control {
        font-size: 0.65rem !important;
        padding: 0 0.55rem !important;
        height: 26px;
      }
    }

  }
}

