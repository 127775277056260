//
// Prospects
//

.prospects-page {
    .nav-pills {
        .badge {
            margin-left: 8px;
            padding: 0.25rem 0.375rem;
        }
        .badge-success {
            color: $white;
            background-color: $success;
        }

        .badge-primary {
            color: $white;
            background-color: $primary;
        }

        .badge-warning {
            color: $gray-900;
            background-color: $warning;
        }

        .badge-danger {
            color: $white;
            background-color: $danger;
        }

        .nav-link.active,
        .show > .nav-link {
            color: $nav-pills-link-active-color;
            background-color: $info;
            .badge {
                color: $info !important;
                background-color: $white !important;
            }
        }

    }
    .filter-dropdown {
        .dropdown-menu {
            padding: 12px 16px 4px;
            min-width: 460px;
        }
    }
    .filter-badges {
        .badge {
            text-transform: none;
            margin-bottom: 6px;
            margin-right: 8px;

            i {
                cursor: pointer;
            }
        }
    }

}
