.btn{
  .btn-inner--icon{
    margin-right: 4px;
  }
  &:hover {
    cursor: pointer;
  }
}
.btn + .btn {
  margin-left: 4px;
}
