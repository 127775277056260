//
// Profile card
//

.card-profile-image {
    position: relative;

    img {
        min-width: 180px;
        min-height: 180px;
        max-width: 180px;
        max-height: 180px;
        object-fit: cover;
        object-position: center;
        border-radius: $border-radius;
        @extend .shadow;
        transform: translate(-50%,-30%);
        position: absolute;
        left: 50%;
        transition: $transition-base;

        &:hover {
            transform: translate(-50%, -33%);
        }
    }
}

.card-profile-stats {
    padding: 1rem 0;

    > div {
        text-align: center;
        margin-right: 1rem;
        padding: .875rem;

        &:last-child {
            margin-right: 0;
        }

        .heading {
            font-size: 1.1rem;
            font-weight: bold;
            display: block;
        }
        .description {
            font-size: .875rem;
            color: $gray-500;
        }
    }
}

.card-profile-actions {
    padding: .875rem;
}

.loan-indicator {
    position: absolute;
    top: 8px;
    right: 8px;
}

.agent-profile-name {
    text-align: center;
}

.logo-profile {
    position: absolute;
    left: 12px;
    top: 6px;
    height: 42px;
}

@media only screen and (max-width: 600px) {
    .agent-profile-name {
        text-align: center;
        h2 {
            font-size: 14px;
        }
    }
}

