//
// Header
//

.header {
    position: relative;

    img {
        width: 240px;
    }
}
